import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Container,
	Image,
	Segment,
	Label,
    Icon,
    Card,
} from "semantic-ui-react";
import MainMenu from "../Components/MainMenu";
import DSBanWhite from "../img/DSBanWhite.png";
import BottomBar from "../img/BottomBar.png";
import Covid from "../img/kdsi-covid-700x300.jpg";

function Research(props) {

	return (
		<>
            <div>
			<Container fluid textAlign='center'>				
				<a href="https://kydataseam.com/"><Image src={DSBanWhite} /></a>
				<MainMenu activeItem={props.activeItem}/>
				<h1> Research </h1>
				<Segment style={{ fontSize: 18, paddingLeft: "10%",paddingRight: "10%"}}>
				    <Image src={Covid} centered/>
					<Segment style={{ fontSize: 20, backgroundColor: 'rgba(190,190,190,90)',padding: 15}} textAlign='center'>
					Dataseam has a nearly 20 year history of facilitating High Performance Distributed Computing for use in academic research.
					<br/>
					<br/>
					More information detailing our current efforts coming soon.
					</Segment >
				</Segment>
                    <Segment.Group horizontal>
					<Segment
						compact
						textAlign="right"
						style={{ fontSize: 18, minWidth:"180",backgroundColor: "rgba(230,220,220,100)" }}
					>
						Dataseam 
						<Icon name="address book outline" size="large" />
					</Segment>
					<Segment textAlign="left" float="left" style={{ minWidth:"180",padding: 10 }}>
						<Icon name="building outline" size="large" /> 1864 Frankfort Avenue,
						Louisville, KY 40206
						<br />
						<Icon name="mail outline" size="large" />
						<a mailto="info@kydataseam.com">info@kydataseam.com</a>
					</Segment>
                    </Segment.Group>
               <span style={{ textAlign: "left", fontSize: 12 }} >&copy; 2022 Dataseam</span>
			</Container>
			</div>
		</>
	);
}

export default Research;
