import './App.css';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from "react-router-dom";
import About from "./pages/About";
import CaptchaTraining from "./pages/CaptchaTraining";
import Research from "./pages/Research";
import Apprenticeship from './pages/Apprenticeship';

function App() {
    
  return (
      <Router>
        <Routes>
          /* How to pass the prop into the sub element here */
          <Route exact path="/" element={<Redirect to="/Training" activeItem="Training"/>} />
          <Route exact path="/About/:active" element={<About />} />
          <Route exact path="/About" element={<About activeItem="About"/>} />
          <Route exact path="/Training" element={<CaptchaTraining activeItem="Training"/>} />
          <Route exact path="/Research" element={<Research activeItem="Research"/>} />
          <Route exact path="/Apprenticeship" element={<Redirect to="https://apprentice.kydataseam.com/" activeItem="Apprenticeship"/>} />
          <Route exact path="/ApprenticeshipD" element={<Apprenticeship activeItem="Apprenticeship"/>} />
        </Routes>
      </Router>
  );
}

export default App;

function Redirect(props) {
  window.location.replace(props.to);
}