import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Container,
	Header,
	Image,
	Segment,
	Label,
	Icon,
	Grid,
} from "semantic-ui-react";
import MainMenu from "../Components/MainMenu";
import "semantic-ui-css/semantic.min.css"
import { SocialIcon } from "react-social-icons";
import DSBanWhite from "../img/DSBanWhite.png";
import BottomBar from "../img/BottomBar.png";
import MorganBanner from "../img/MorganBanner.png";

function About(props) {
	return (
		<>
			<div>
			<Container fluid textAlign='center'>				
				<a href="https://kydataseam.com/"><Image src={DSBanWhite} /></a>
				<MainMenu activeItem={props.activeItem}/>
				<Segment align='center'>
					<h1> Building something great -- check back soon</h1>
					<Image src={MorganBanner}/> 
						<Segment style={{ fontSize: 20, }} textAlign='center'>
							Connect with us on Social Media.
							<br/>
							<br/>
							<Grid columns={4}>
			    				<Grid.Column><SocialIcon network="facebook" url="https://facebook.com/dataseam" /></Grid.Column>
			    				<Grid.Column><SocialIcon network="twitter" url="https://twitter.com/dataseam" /></Grid.Column>
			    				<Grid.Column><SocialIcon network="linkedin" url="https://linkedin.com/company/dataseam" /></Grid.Column>
			    				<Grid.Column><SocialIcon network="instagram" url="https://instagram.com/dataseam" /></Grid.Column>
							</Grid>
						</Segment>
				</Segment>
				<Segment.Group horizontal>
					<Segment
						compact
						textAlign="right"
						style={{ fontSize: 18, minWidth:"180",backgroundColor: "rgba(230,220,220,100)" }}
					>
						Dataseam 
						<Icon name="address book outline" size="large" />
					</Segment>
					<Segment textAlign="left" float="left" style={{ minWidth:"180",padding: 10 }}>
						<Icon name="building outline" size="large" /> 1864 Frankfort Avenue,
						Louisville, KY 40206
						<br />
						<Icon name="mail outline" size="large" />
						<a mailto="info@kydataseam.com">info@kydataseam.com</a>
					</Segment>
                </Segment.Group>
               <span style={{ textAlign: "left", fontSize: 12 }} >&copy; 2022 Dataseam</span>
			</Container>
			</div>
		</>
	);
}

export default About;
