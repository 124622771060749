import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Container,
	Header,
	Image,
	Segment,
	Menu,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css"
function MainMenu(props) {
/*
    var { active } = useParams();
	const [activeItem, setActiveItem] = useState();

    useEffect(() => {
        if ( active && active !== activeItem ){
            setActiveItem(active);
            active = undefined;
        }
    })

	*/
    let navi = useNavigate();
	const handleMenuClick = (e, { name }) => {
		//console.log("handleMenuClick:",  name);
        navi('/'+name)
	};
	return (
		<>
				<Container fluid  textAlign='center'>
					<Menu stackable style={{ marginTop: 0, fontSize: 20 }} pointing >
						<Menu.Item
							name="About"
							active={props.activeItem === "About"}
							onClick={handleMenuClick}
						/>
						<Menu.Item
							name="Apprenticeship"
							active={props.activeItem === "Apprenticeship"}
							onClick={handleMenuClick}
						/>
						<Menu.Item
							name="Training"
							active={props.activeItem === "Training"}
							onClick={handleMenuClick}
						/>
						<Menu.Item
							name="Research"
							active={props.activeItem === "Research"}
							onClick={handleMenuClick}
						/>
					</Menu>
				</Container>
		</>
	);
}

export default MainMenu;
