import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Container,
	Image,
	Segment,
	Menu,
} from "semantic-ui-react";
import MainMenu from "../Components/MainMenu";

function Apprenticeship(props) {

	return (
		<>
			<div>
				<a href="https://kydataseam.com/">TopBar Image Here </a>
                <h1>IT Apprenticeship</h1>
				<Container fluid  textAlign='centered'>
                <MainMenu activeItem={props.activeItem}/>
				</Container>
                    https://apprentice.kydataseam.com
                <span style={{ textAlign: "left" }} >&copy; 2021-2022 Dataseam</span>
			</div>
		</>
	);
}

export default Apprenticeship;
