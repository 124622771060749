import React, { useState, useEffect, Link } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Container,
	Image,
	Segment,
	Label,
	Icon,
	Card,
	Divider,
	Message,
	Header,
	Button,
	List,
	Modal,
	Dropdown,
} from "semantic-ui-react";
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { api } from "../Components/Enroll";
import { Formik } from "formik";
import { Select, Checkbox, Input, Form, SubmitButton } from "formik-semantic-ui-react";
import * as Yup from "yup";

import MainMenu from "../Components/MainMenu";
import DSBanWhite from "../img/DSBanWhite.png";
import BottomBar from "../img/BottomBar.png";
import ClassWide from "../img/ClassWide.png";

export const enrollScheme = Yup.object().shape({
	trainingClass: Yup.string().min(5).required(),
	email: Yup.string().email().required(),
	firstName: Yup.string().min(1).required(),
	lastName: Yup.string().min(1).required(),
	district: Yup.string().min(4).required(),
	po: Yup.string().min(1).required(),
});

function Training(props) {
	const [open, setOpen] = useState(false);
	const [serverError, setServerError] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState(undefined);
	const [requestComplete, setRequestComplete] = useState(undefined);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const classes = [
		{
			key: "nf",
			value: "Nov 14 2022",
			text: "November 14 - 17 2022 KY K12 Apple Training and Certfication Class",
		},
		{
			key: "nt",
			value: "Nov 28 2022",
			text: "November 28 - Dec 1 2022 KY K12 Apple Training and Certfication Class",
		},
	];
    
	async function makeRequest(fields) {
		console.log("Form Being Submitted!!!!");
        setRequestComplete(false);
        setServerErrorMessage(fields.email);
		console.log(fields);
		let res;
		// Call recaptcha now and get the returned Token
		const retoken = await executeRecaptcha("enroll");
		// Add token to fields called to API
		// modify API to send Token to server.
		// Unpack on server w/ Secret key and validate
		// Return serverError="I robot; you robot" if failure or score < .6 or so
		res = await api
			.enroll(
				fields.trainingClass,
				fields.firstName,
				fields.lastName,
				fields.email,
				fields.district,
				fields.po,
				fields.diet,
				fields.hotel,
				retoken
			)
   /*
			.then((response) => {
				console.log("SignUp(response): ", response);
				if (response.data) {
					if (response.data.serverError) {
						setServerError(response.data.serverError);
						console.log("Server Error: ", response.data.serverError);
					} else {
						setRequestComplete(response.data);
						console.log("Request Complete: ", response.data);
					}
				}
			})
			.catch((error) => {
				console.log("Error", error);
			});
            */
		console.log("Enroll(res): ", res);
        if ( res.status != 200) {
            console.log("An Error occurred", res.data.message);
            setServerError(true);
            setServerErrorMessage(res.data.message);
        } else {
            console.log("Success", res.data.message);
            setServerError(false);
            setRequestComplete(true);
        }
		setOpen(false);
		return "Some Message";
	}

	return (
		<>
				<Container fluid textAlign="center">
					<a href="https://kydataseam.com/">
						<Image src={DSBanWhite} />
					</a>
					<MainMenu activeItem={props.activeItem} />
							<h1> Professional IT Training</h1>
							<Segment style={{ fontSize: 18,paddingLeft:"10%",paddingRight:"10%" }}>
								<Image src={ClassWide} />
								<Segment
									style={{
										fontSize: 18,
										backgroundColor: "rgba(210,200,200,120)",
										padding: 15,
									}}
									textAlign="center"
								>
									Primarily supporting IT workers in the K-12 environment, Dataseam
									provides technical training classes, many of which lead to the award of
									industry standard certifications.
									<br />
								</Segment>
								<Divider horizontal>
									<Header as="h3">
										<Icon name="student" />
										Current Classes
									</Header>
								</Divider>
								<Segment>
									<h3>Kentucky K12 Enhanced Apple Device Support Certification Training</h3>
									Learn the skills you will need as an IT Professional to support and
									troubleshoot Apple devices inside a K12 organization.
									<br />
									The course will cover all of Apple's software platforms: iOS, iPadOS,
									macOS, watchOS, and tvOS. Topics include:
									<List bulleted style={{textAlign:"left", marginLeft:"25%"}}>
										<List.Item>Device Backup, Restore, and Migration.</List.Item>
										<List.Item>
											Reviving and Recovering devices with Apple Configurator.
										</List.Item>
										<List.Item>
											Configuring and troubleshooting Network connectivity including
											AirDrop, VPNs, HotSpots and more.
										</List.Item>
										<List.Item>
											Managing privacy, FileVault, login and Apple ID issues.
										</List.Item>
										<List.Item>
											Mobile Device Management Configuration and options.
										</List.Item>
										<List.Item>
											Troubleshooting with Activity Monitor and Terminal.
										</List.Item>
										<List.Item>And more!</List.Item>
									</List>
									After completing the class you will have an opportunity to get certified
									by earning the  &nbsp;
									<a href="https://www.credly.com/organizations/apple/badges">
										 Apple Certified Professional digital badge
									</a>
									 &nbsp; from Apple and Credly.com .
								</Segment>
								<Divider horizontal>
									<Header as="h3">
										<Icon name="calendar alternate" />
										Upcoming Dates
									</Header>
								</Divider>
								<Message floating compact>
									<Message.Header>
										<Icon name="calendar alternate outline" size="large" />
										November 14 - November 17 2022
									</Message.Header>
									<Message.Content style={{paddingTop:5}}><b><i>Price:</i></b> $4,000 <Icon name='map outline' size='small'/><b><i>Location:</i></b> Downtown Louisville</Message.Content>
								</Message>
								<br />
								<Message floating compact>
									<Message.Header>
										<Icon name="calendar alternate outline" size="large" />
										November 28 - December 1 2022
									</Message.Header>
									<Message.Content style={{paddingTop:5}}><b><i>Price:</i></b> $4,000 <Icon name='map outline' size='small'/><b><i>Location:</i></b> Downtown Louisville</Message.Content>
								</Message>
								<br />
								<b>
									<i>
										All training classes run 9am - 5pm. Classes are capped at 16, so
										register early!
									</i>
								</b>
								<br />
								<br />
								<Modal
									onClose={() => setOpen(false)}
									onOpen={() => setOpen(true)}
									open={open}
									trigger={
										<Button animated icon labelPosition="left" size="huge" positive>
                                            <Button.Content visible>
											    <Icon name="pencil alternate" size="large" /> Sign up to Attend
                                            </Button.Content>
                                            <Button.Content hidden>
                                                <Icon name='thumbs up outline' size='large' />Click to Register
                                            </Button.Content>
										</Button>
									}
								>
									<Modal.Header>Signup for Training</Modal.Header>
									<Modal.Content>
                                        <h4 style={{textAlign: "center"}}>
										Complete the form below and click Sign Up to submit your registration. Contact <a mailto="training@kydataseam.com">training@kydataseam.com</a> with any questions!
                                        </h4>
										<>
											<Formik
												initialValues={{
													trainingClass: "",
													email: "",
													recaptcha: "",
													district: "",
													firstName: "",
													lastName: "",
													hotel: false,
													diet: "",
												}}
												validationSchema={enrollScheme}
												enableReinitialize
												onSubmit={(values) => makeRequest(values)}
											>
												<Form>
													<Select
														placeholder="Select class and date"
														name="trainingClass"
														options={classes}
														required
														errorPrompt
													/>
													<Input
														required
														name="email"
														inputLabel="Attendee Email"
														icon="mail"
														errorPrompt
													/>
													<Input
														required
														name="firstName"
														inputLabel="First Name"
														icon="user"
														errorPrompt
													/>
													<Input
														required
														name="lastName"
														inputLabel="Last Name"
														icon="user"
														errorPrompt
													/>
													<Input
														required
														name="district"
														inputLabel="District"
														icon="graduation"
														errorPrompt
													/>
													<Input
														required
														name="po"
														inputLabel="P.O. #"
														icon="wpforms"
														errorPrompt
													/>
													If you have any dietary restrictions please detail them below.
													Leave blank if you have none.
													<Input name="diet" icon="food" errorPrompt />
													<Checkbox
														name="hotel"
														label="Do you anticipate staying in the hotel where the training is being held?"
														icon="hotel"
														errorPrompt
													/>
													<SubmitButton positive>
														<Icon name="check" />
														Sign Up Now
													</SubmitButton>
												</Form>
											</Formik>
										</>
									</Modal.Content>
									<Modal.Actions>
										<Button
                                            negative
											content="Abort! Just looking!"
											labelPosition="left"
											icon="undo"
											onClick={() => setOpen(false)}
										/>
									</Modal.Actions>
								</Modal>
                            {serverError?<Message negative> An Error has occurred: {serverErrorMessage} </Message>:""}
                            {requestComplete?<Message positive> A request for enrollment has been submitted for {serverErrorMessage}! </Message>:""}
                            <br/>
                            <br/>
                            Contact <a mailto="training@kydataseam.com">training@kydataseam.com</a> for more information, or to make a special request.
							</Segment>
							<Segment.Group horizontal>
								<Segment
									compact
									textAlign="right"
									style={{ fontSize: 18, minWidth:"180",backgroundColor: "rgba(230,220,220,100)" }}
								>
									Dataseam
									<Icon name="address book outline" size="large" />
								</Segment>
								<Segment textAlign="left" float="left" style={{ minWidth:"180",padding: 10 }}>
									<Icon name="building outline" size="large" /> 1864 Frankfort Avenue,
									Louisville, KY 40206
									<br />
									<Icon name="mail outline" size="large" />{" "}
									<a mailto="info@kydataseam.com">info@kydataseam.com</a>
								</Segment>
							</Segment.Group>
					<span style={{ textAlign: "left", fontSize: 12 }}>&copy; 2022 Dataseam</span>
				</Container>
		</>
	);
}

export default Training;
