import React, { useContext, useReducer } from 'react';
import axios from 'axios';

const baseUrl = "https://training.kydataseam.com:/api";

export const api = {
    enroll: (trainingClass, firstName, lastName, email, district, po, diet, hotel,retoken ) => {
        //console.log("Enrolling: ",trainingClass, firstName,lastName,email,district,po,diet,hotel,retoken);
        //var result = axios.post(`${baseUrl}/enroll`, {firstName:firstName,lastName:lastName,email:email,district:district,hotel:hotel,po:po,diet:diet})
        var result =  axios.post(`${baseUrl}/enroll`, {trainingClass, firstName,lastName,email,district,po,diet,hotel,retoken})
        .then((res) => {
            //console.log(res);
            return res;
        })
        .catch((error) => {
            console.log("enrollment error",error);
            return error.response;
        })
        return result;
    },
    noop: (body) => {
        var result = axios.post(`${baseUrl}/noop`,body)
        .then((res) => {
            //console.log(res);
            return res.data;
        })
        .catch((error) => {
            console.log("noop error",error);
            return error;
        })
        return result;
    }
}