import * as React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import  Training  from './Training';

// This is just a wrapper for the Captcha Provider... RequetAccount must grok and pass the Captcha 411
function CaptchaTraining(props) {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6Ld-GWwaAAAAAJtICV8ll4TV7oCEBD0F1-eITycO" // FIXME, use process.env.SITEKEY or something?
            scriptProps={{ async: true, defer: true, appendTo: 'body'}}o
        >
            <Training activeItem={props.activeItem}/>
        </GoogleReCaptchaProvider>
    )
}

export default CaptchaTraining;